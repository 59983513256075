import { FC, useEffect, useReducer, useCallback } from 'react'
import Context from './Context'
import {IAuth, IAuthAction} from "./types"
import jwtDecode, { JwtPayload } from "jwt-decode"
import { useWebSettings } from '../WebSettings'
import { useLocation, useNavigate } from 'react-router'
import { authorizedApiClient, noCredentialApiClient  } from '../../services/clients'
import axios, { AxiosResponse } from 'axios'


function reducer(state: IAuth, action: IAuthAction) {
    const { type, auth } = action

    switch (type) {
        case 'SET_AUTH':
            return auth
        case 'UPDATE_AUTH':
            return { ...state, ...auth }
        default:
            throw new Error()
    }
}


const Provider : FC<{persistKey: string, children: any}>= ({ persistKey = 'auth', children }) => {
    const { getWebSettingsValue = (name: string) => 10 } = useWebSettings()
    const DEFAULT_CHECK_INTERVAL = parseInt(getWebSettingsValue("login_expiration_check") as string || "10")
    const authCheckInterval = DEFAULT_CHECK_INTERVAL * 60 * 1000

    const persistAuth = JSON.parse(localStorage.getItem(persistKey) || '{}') as IAuth
    const location = useLocation()
    const navigate = useNavigate()
    const [auth, dispatch] = useReducer(reducer, persistAuth || {})

    const checkIntervalTimer = setInterval(() => {
        handleExpired()
    }, authCheckInterval)

    useEffect(() => {
        var authorizedRequestInterceptor: number | undefined = undefined

        const emptyRequest = { handlers: [] }
        const interceptors = authorizedApiClient.interceptors ?? { request: emptyRequest }
        
        if (((interceptors.request ?? emptyRequest) as any).handlers.length == 0) {
            authorizedRequestInterceptor = authorizedApiClient.interceptors.request.use(
                async (config) => {
                    const headers = config.headers || {}

                    const storedAuth = JSON.parse(localStorage.getItem("auth") || "{}")
        
                    if (!storedAuth.isAuthenticated || !storedAuth.refreshToken || !storedAuth.accessToken) Promise.reject("Unauthorized")
                    
                    if (isTokenExpired(storedAuth.refreshToken)) {
                        logout()
                        return Promise.reject("Login expired")
                    } else if (isTokenExpired(storedAuth.accessToken)) {
                        var response: AxiosResponse

                        try {
                            response = await noCredentialApiClient.post("api/tokens/refresh/", { refresh: storedAuth.refreshToken })
                        } catch(e) {
                            logout()
                            return Promise.reject("Login expired")
                        }

                        const { access, refresh } = response.data
                        
                        const headers = config.headers || {}
                        headers.Authorization = `Bearer ${access}`
        
                        var newTokens: IAuth = { accessToken: access }
        
                        if (refresh) newTokens.refreshToken = refresh
        
                        interceptorAuthUpdate(newTokens)
                    } else {
                        headers.Authorization = `Bearer ${storedAuth.accessToken}`
                    }

                    if (config.data && (config.data.constructor === FormData)) {
                        headers["Content-Type"] = "multipart/form-data"
                    }
            
                    return config
                },
                (error) => Promise.reject(error)
            )
        }

        return (() => {
            clearInterval(checkIntervalTimer)

            authorizedRequestInterceptor && axios.interceptors.request.eject(authorizedRequestInterceptor)
        })
    }, [])

    const handleExpired = (): void => {
        if (!auth.isAuthenticated || (location.pathname == "/signin")) return

        const refreshToken = JSON.parse(localStorage.getItem(persistKey) || '{}').refreshToken
        const utcNow = new Date().getTime()

        if (!refreshToken || ((jwtDecode<JwtPayload>(String(refreshToken)).exp ?? 0) * 1000 < utcNow)) logout()
    }

    const isTokenExpired = (token: string) => {
        const now = new Date().getTime()
        const exp = (jwtDecode<JwtPayload>(String(token)).exp ?? 0) * 1000

        return exp < now
    }

    const setAuth = (auth: IAuth) => {
        dispatch({ type: 'SET_AUTH', auth })
    }

    const updateAuth = (auth: IAuth) => {
        dispatch({ type: 'UPDATE_AUTH', auth })
    }

    const logout = () => {
        setAuth({ isAuthenticated: false })
        navigate("/signin")
    }

    const interceptorAuthUpdate = useCallback((data: IAuth) => updateAuth(data), [updateAuth])

    useEffect(() => {
        try {
            if ((auth as any).token) {
                setAuth({ isAuthenticated: false })
                localStorage.removeItem("expireOn")
                window.location.reload()
                return
            }

            localStorage.setItem(persistKey, JSON.stringify(auth))

            if (auth.refreshToken) handleExpired()
        } catch (error) {
            console.warn(error)
        }
    }, [auth, persistKey])

    return (
        <Context.Provider value={{ auth, setAuth, updateAuth, handleExpired, logout }}>
            {children}
        </Context.Provider>
    )
}

export default Provider