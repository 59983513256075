import axios, { AxiosInstance } from "axios"


const getBaseUrlFromHost = (): string => {
    if (/localhost|127.0.0.1/.test(window.location.host)) {
        return String(process.env.API_URL_BASE)
    }

    const host = window.location.host
    var subdomains = host.split(".")
    subdomains[0] += "-be"

    if (window.location.host.split(".").length < 4) {
        subdomains[0] += ".production"
    }

    return `https://${subdomains.join(".")}`
}


const authorizedApiClient: AxiosInstance = axios.create({
    baseURL: getBaseUrlFromHost(),
    headers: {
        "Content-Type": "application/json"
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

const unauthorizedApiClient = (headers?: { [key: string] : string}): AxiosInstance => axios.create({
    baseURL: getBaseUrlFromHost(),
    headers: {
        "Content-Type": "application/json",
        ...headers
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

const noCredentialApiClient: AxiosInstance = axios.create({
    baseURL: getBaseUrlFromHost(),
    headers: {
        "Content-Type": "application/json"
    },
    transformResponse: [(data) => {
        if (data !== "") return JSON.parse(data)
    }],
})

const plainClient = (): AxiosInstance => axios.create({})


export {
    authorizedApiClient,
    unauthorizedApiClient,
    noCredentialApiClient,
    plainClient
}